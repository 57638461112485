const VERSION = 1;

const KEY_PREFS = "npp:prefs";
const KEY_PREFS_VERSION = "npp:prefs:version";

const KEY_CLIENT_TOKEN = "client_token";
const KEY_SPLIT_GROUP_ID = "split_group_id";
const KEY_WEBVIEW_REQUEST_PARAMS = "webview_request_params";

class ClientStorage {

  constructor() {
    const prefs = window.localStorage.getItem(KEY_PREFS);
    if (prefs) {
      this.prefs = JSON.parse(prefs);
    } else {
      this.prefs = {};
    }

    const currentVersion = parseInt(window.localStorage.getItem(KEY_PREFS_VERSION) || 1);
    if (currentVersion !== VERSION) {
      this.migrate(currentVersion, VERSION);
      window.localStorage.setItem(KEY_PREFS_VERSION, "" + VERSION);
    }
  }

  migrate(fromVersion, toVersion) {
    // ...
  }

  commit() {
    window.localStorage.setItem(KEY_PREFS, JSON.stringify(this.prefs));
  }

  setBoolean(key, value) {
    this.prefs[key] = !!value;
    this.commit();
  }

  getBoolean(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return !!this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  setInteger(key, value) {
    this.prefs[key] = parseInt(value);
    this.commit();
  }

  getInteger(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return parseInt(this.prefs[key]);
    } else {
      return defaultValue;
    }
  }

  setString(key, value) {
    this.prefs[key] = "" + value;
    this.commit();
  }

  getString(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return "" + this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  // ---

  getClientToken() {
    return this.getString(KEY_CLIENT_TOKEN);
  }

  setClientToken(value) {
    this.setString(KEY_CLIENT_TOKEN, value);
  }

  setWebviewRequestParams(value) {
    if (typeof value !== 'object' || value === null) {
      value = {};
    }

    this.prefs[KEY_WEBVIEW_REQUEST_PARAMS] = value;
    this.commit();
  }

  getWebviewRequestParams() {
    if (this.prefs[KEY_WEBVIEW_REQUEST_PARAMS] === undefined) {
      return {};
    }

    return this.prefs[KEY_WEBVIEW_REQUEST_PARAMS];
  }

  getSplitGroupId() {
    return this.getInteger(KEY_SPLIT_GROUP_ID, undefined);
  }

  setSplitGroupId(value) {
    this.setInteger(KEY_SPLIT_GROUP_ID, value);
  }
}

export default new ClientStorage();